<template>
  <section class="product-section">
    <h3 class="section-title section-title--white">
      Designed & Developed by Us
    </h3>
    <div class="product-section__card-container container-fluid">
      <ProductCard
        v-for="({ link, category, title, text, technologies }, index) in cards"
        :key="index"
        class="product-section__card"
        :link="link"
        :category="category"
        :title="title"
        :text="text"
        :technologies="technologies"
      />
    </div>
  </section>
</template>

<script>
import ProductCard from "./ProductCard.vue";

export default {
  name: "ProductSection",
  components: { ProductCard },
  data() {
    return {
      cards: [
        {
          link: "https://theoptimizer.io/",
          category: "optimizer",
          title: "TheOptimizer",
          technologies:
            "Node.js, Vue.js, ClickHouse, PostgreSQL, MongoDB, Docker, Kubernetes, Jenkins, RabbitMQ, and many more.",
          text:
            "A SaaS platform designed to automate the performance of native ads. Since its launch in 2017, it has grown rapidly to become the industry�s benchmark solution.",
        },

        {
          link: "https://landerlab.io/",
          category: "landerlab",
          title: "LanderLab",
          technologies:
            "Golang, Vue.js, PostgreSQL, Docker, Kubernetes, Jenkins.",
          text:
            "Launched in 2019 with the aim of helping digital marketers, create and host their landing pages without the need of technical skills.",
        },
        {
          link: "https://clickflare.io/",
          category: "clickflare",
          title: "ClickFlare",
          technologies:
            "Node.js (TypeScript), ClickHouse, Kafka, Vue.js, Docker, Kubernetes, Jenkins.",
          text:
            "Recently launched, ClickFlare is a tracking platform that helps its users easily track the performance of their marketing campaigns, while not requiring any technical background from them.",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.product-section {
  position: relative;
  margin-top: 16rem;
  margin-bottom: 16rem;
  padding-top: 6rem;
  padding-bottom: 12rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background-color: $color-primary;
    width: 141rem;
    height: 100%;
    border-bottom-left-radius: 320px;
    z-index: -1;

    @include media-breakpoint-down(xxl) {
      width: 120rem;
    }
    @include media-breakpoint-down(xl) {
      width: 114rem;
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  & .section-title--white {
    margin: 0 auto;
    margin-bottom: 6rem;
    text-align: center;
    font-size: 3.5rem;
    width: 100%;

    // change when backoground is added
    color: $color-white;

    @include media-breakpoint-down(md) {
      font-size: 3rem;
    }
  }

  .product-section__card-container {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    column-gap: 3rem;
    justify-items: center;
    justify-content: center;
    max-width: 138rem;

    @include media-breakpoint-down(lg) {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 4rem;
    }
  }
}
</style>
